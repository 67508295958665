import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token_admin');
    const hasAuthHeader = request.headers.has('Authorization');

    if (!hasAuthHeader && token) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${(JSON.parse(token) as AuthAPI.Token).accessToken}`
        }
      });
    }
    return next.handle(request).pipe(
      tap(() => {}),
      finalize(() => {})
    );
  }
}
