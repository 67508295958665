import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppSecurityModule } from '@soctrip-common/app-security';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AUTH_INTERCEPTOR_PROVIDER } from './core/interceptors/auth/auth.interceptor';
import { ToastMessageComponent } from './shared/components/toast-message/toast-message.component';
import localeVi from '@angular/common/locales/vi';
import { registerLocaleData } from '@angular/common';

// Registry supported language
registerLocaleData(localeVi, 'vi');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent, ToastMessageComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    StoreModule.forRoot({ router: routerReducer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    ToastModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppSecurityModule.forRoot()
  ],
  providers: [
    MessageService,
    DynamicDialogRef,
    DialogService,
    DynamicDialogConfig,
    AUTH_INTERCEPTOR_PROVIDER
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translateService: TranslateService) {
    let lang = environment.languages[0];
    this.translateService.addLangs(environment.languages);
    const browserLang = this.translateService.getBrowserLang() || lang;
    const selectedLang = localStorage.getItem('lang') || '';
    if (selectedLang && environment.languages.includes(selectedLang)) {
      lang = selectedLang;
    } else if (!selectedLang && environment.languages.includes(browserLang)) {
      lang = browserLang;
    }
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
  }
}
